import { Category } from "@lib/shared/types";
import styles from "./styles.module.scss";
import S3Icon from "@components/shared/s3Icon";

export default function CategoryBlock({
    category,
    isDisabled,
    isChecked,
    onClick,
}: {
    category: Category;
    isDisabled?: boolean;
    isChecked?: boolean;
    onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <div
            className={`flex items-center justify-center ${
                styles["checkbox-wrapper"]
            } ${isDisabled ? styles["disabled"] : ""}`}
        >
            <input
                type="checkbox"
                id={category.slug}
                name={category.slug}
                value={category.slug}
                checked={isChecked}
                onChange={onClick}
            />
            <label
                htmlFor={category.slug}
                className={`flex items-center justify-center ${styles["checkbox-label"]}`}
            >
                <span className={styles["box"]}>
                    <svg viewBox="0,0,50,50">
                        <path d="M5 30 L 20 45 L 45 5"></path>
                    </svg>
                </span>

                {category.icon && (
                    <S3Icon
                        image={category.icon}
                        svgClassName={styles["category-icon"]}
                    />
                )}

                <span
                    style={{
                        width: "100%",
                    }}
                    className={styles["category-name"]}
                >
                    {category.label || category.name}
                </span>
            </label>
        </div>
    );
}
