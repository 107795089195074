import React, { ReactElement, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import colors from "@styles/variables/quotoVariables.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { getDomainForm } from "src/api";
import Form from "@components/shared/form";
import CategoryBlock from "../categoryBlock";
import Loader from "@components/shared/loader";

export default function MainBanner(): ReactElement {
    const { domain, formattedTitle } = useDomainContext();
    const { query: { s: selectedCategoriesQueryParam } = {} } = useRouter();

    const [selectedCategories, setSelectedCategories] = useState<string[]>([
        ...new Set(
            selectedCategoriesQueryParam
                ? Array.isArray(selectedCategoriesQueryParam)
                    ? selectedCategoriesQueryParam
                    : selectedCategoriesQueryParam.split(",")
                : [],
        ),
    ]);

    const categories = domain?.categories ?? [];
    const MAX_SERVICE_NUMBER = 2;

    const { form, updateForm } = useDomainContext();
    const [isFormFetched, updateFormFetched] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [formError, setFetchFormError] = useState(false);
    const [loading, setLoading] = useState(false);

    const getForm = async (categorySlug: string) => {
        setLoading(true);
        const { data, error } = await getDomainForm({
            categorySlug,
        });
        setLoading(false);
        if (error) {
            setFetchFormError(true);
        }
        if (data) {
            setFetchFormError(false);
            updateForm(data);
            updateFormFetched(true);
            setCurrentStep(1);
        }
    };

    const handleNext = () => {
        void getForm(selectedCategories.join(","));
    };

    const formContent = useMemo(
        () =>
            isFormFetched ? (
                <div key={form?.title} className={`${styles.formOtherSteps}`}>
                    <Form
                        classes={{
                            formClassName: `${styles["form"]}`,
                            stepTitleClassName: styles["step-title"],
                            stepClassName: styles["step-block"],
                            stepDescriptionClassName:
                                styles["step-description"],
                            formButtonClassName: styles["form-button"],
                            formFieldClassName: styles["form-field"],
                            formFieldLabelClassName: styles["field-label"],
                            formStepInnerClassName: styles["steps-inner"],
                            fieldsClassName: {
                                radio: styles["radio-field"],
                                checkbox: styles["checkbox-field"],
                                zipcodeExpandable: styles["zipcode-expandable"],
                                streetAddress: styles["street-address"],
                            },
                            fieldsWrapperClassName: styles["fields-wrapper"],
                            tcpaClassName: styles["tcpa"],
                            labelAsTitleClassName:
                                styles["label-as-step-title"],
                            formSubmitButtonClassName: styles["submit-button"],
                            sellingPointClassName: styles["selling-point"],
                            formProgressbarClassName: styles["custom-progress"],
                            stepTagClassName: styles["step-tag"],
                            fieldNoteClassName: styles["field-note"],
                        }}
                        colors={{
                            primaryColor: colors.blue,
                            progressBar: colors.blue,
                        }}
                        showProgress={true}
                        progressShift={1}
                        backButton={{
                            class: styles["back-button"],
                            label: "Back",
                            firstStepAction: () => {
                                setCurrentStep(0);
                            },
                        }}
                        goToThankYouPage={true}
                        dynamicThankYouFields={["firstName"]}
                        preventWindowClose
                        stepsConfig={[
                            {
                                stepOrder: 0,
                                hideTitle: false,
                                hideDescription: false,
                                forceStepTitle: true,
                            },
                        ]}
                        ignoreOptionIcons
                        ignoreCategoryInThankYouPagePath
                    />
                </div>
            ) : null,
        [isFormFetched, form],
    );

    return (
        <div className={styles["main-banner"]}>
            <div
                style={{
                    width: "100%",
                    maxWidth: currentStep !== 0 ? "700px" : "unset",
                }}
            >
                <div className={`${styles.formWrapper}`}>
                    <div className={`${styles.formStepsWrapper}`}>
                        <div
                            style={{
                                transform: `translate(${
                                    currentStep * -100
                                }%, 0)`,
                            }}
                            className={`${styles.formStepsWrapperWindow}`}
                        >
                            <div className={`${styles.stepBlock}`}>
                                <div
                                    style={{ color: colors.poseidon }}
                                    className="text-center mb-6 mt-4 sm:mt-10"
                                >
                                    <h1
                                        className="font-bold text-xl lg:text-3xl"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                formattedTitle ??
                                                "Get Customized Quotes from Top Providers for Your Business",
                                        }}
                                    ></h1>
                                    <p className="font-light mt-3 max-w-4xl mx-auto">
                                        Complete the form and start comparing
                                        prices to find the best deal!
                                    </p>
                                </div>
                                {formError && (
                                    <div className={styles.errorMessage}>
                                        Something went wrong
                                    </div>
                                )}

                                <p className="text-left lg:ml-[130px] mb-2">
                                    Select up to {MAX_SERVICE_NUMBER} services:
                                </p>
                                <div className={styles["categories-wrapper"]}>
                                    {categories?.map((category) => {
                                        return (
                                            <CategoryBlock
                                                key={category.slug}
                                                category={category}
                                                isDisabled={
                                                    selectedCategories.length ===
                                                        MAX_SERVICE_NUMBER &&
                                                    !selectedCategories.includes(
                                                        category.slug,
                                                    ) &&
                                                    !selectedCategories.includes(
                                                        category.slugAlias,
                                                    )
                                                }
                                                isChecked={
                                                    selectedCategories.includes(
                                                        category.slug,
                                                    ) ||
                                                    selectedCategories.includes(
                                                        category.slugAlias,
                                                    )
                                                }
                                                onClick={() => {
                                                    if (
                                                        selectedCategories.includes(
                                                            category.slug,
                                                        ) ||
                                                        selectedCategories.includes(
                                                            category.slugAlias,
                                                        )
                                                    ) {
                                                        setSelectedCategories(
                                                            selectedCategories.filter(
                                                                (item) =>
                                                                    ![
                                                                        category.slug,
                                                                        category.slugAlias,
                                                                    ].includes(
                                                                        item,
                                                                    ),
                                                            ),
                                                        );
                                                    } else {
                                                        setSelectedCategories([
                                                            ...selectedCategories,
                                                            category.slug,
                                                        ]);
                                                    }
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                                <div className="flex">
                                    <button
                                        className={`${styles.button} ${
                                            selectedCategories.length === 0
                                                ? "opacity-60 cursor-not-allowed"
                                                : ""
                                        } flex items-center justify-center gap-4 relative`}
                                        disabled={
                                            selectedCategories.length === 0
                                        }
                                        onClick={handleNext}
                                    >
                                        {loading ? (
                                            <span>
                                                <Loader size={25} />
                                            </span>
                                        ) : (
                                            <span>Get Started</span>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <div className={`${styles.stepBlock}`}>
                                {formContent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
